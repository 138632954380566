import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { PagerClasses } from '../../../services/models/pager-classes';
import { UserTypeMap } from '../../../services/models/user-type-map';
import { UserStatusMap } from '../../../services/models/user-status-map';
import { PortalUser, UserType, UserStatus } from '../../../services/models/portal-user';
import { PagedResponse } from '../../../services/models/responses/paged-response';
import { CollectionListItem } from '../../../services/models/collection-list-item';
import { CollectionService } from '../../../services/collection.service';
import { UserAdminService } from '../../../services/user-admin.service';
import { SessionService } from '../../../services/session.service';
import { Constants } from '../../../app.constants';
import { Messages } from '../../../app.messages';

@Component({
  selector: 'collection-manager-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  public totalUsers = 0;
  public pageNumber: number;
  public rowsPerPage: number;
  public users: PortalUser[];
  public cssClasses = PagerClasses.cssClasses;
  public editUser: PortalUser;
  public removalUser: PortalUser;
  public showAddUser = false;
  public showAccept = false;
  public showApproveDeclineScreen = false;
  public showUserPermission = false;
  public showUserDeclineScreen = false;
  public selectedUser: PortalUser;
  public collectionItem: CollectionListItem;
  public text: string;
  public results: [];
  private sortColumn: string;
  private sortAscending: boolean;
  private allUsers: PortalUser[];
  private collectionServiceSubscription: Subscription;

  constructor(
    private adminService: UserAdminService,
    private collectionService: CollectionService,
    private confirm: ConfirmationService,
    private messageService: MessageService,
    public session: SessionService,
    private constants: Constants,
    private messages: Messages
  ) {
    this.rowsPerPage = constants.DetailRowsPerPage;
    this.users = [];
    this.editUser = null;
    this.pageNumber = 1;
    this.sortColumn = 'status';
    this.sortAscending = true;
  }

  ngOnInit() {
    this.collectionServiceSubscription = this.collectionService.collectionListItemChanged$
      .subscribe(collection => {
        this.pageNumber = 1;
        this.findUsers(this.pageNumber, true);
        this.collectionItem = collection;
        const user = this.adminService.selectedUser;
        if (user != null && collection != null && collection.id != null && collection.id != 0) {
          if (user.providerCollectionID != null) {
            this.adminService.selectedUser.providerCollectionID = collection.id;
          }
          this.showUsers(1, 1, [user]);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.collectionServiceSubscription) {
      this.collectionServiceSubscription.unsubscribe();
    }
  }

  private showUsers(pageNumber: number, totalUsers: number, users: PortalUser[]): void {
    this.pageNumber = pageNumber;
    this.totalUsers = totalUsers;
    this.allUsers = users;
    if (users != null) {
      this.onPage(pageNumber);
    }
  }

  // Get the class(es) to display for the status icon
  public mapStatusToIcon(user: PortalUser): string {
    return UserStatusMap.mapStatustoIcon(user.status);
  }

  // Get the class(es) to display for the role icon
  public mapRoleToIcon(user: PortalUser): string {
    if (user.status !== UserStatus.Active && user.status !== UserStatus.Approved) {
      return null;
    }
    return UserTypeMap.mapUserRoleTypeToIcon(user.roleID);
  }

  // Get the string to display for the tooltip (title) for the user hovers over the status icon
  public mapStatusToTooltip(user: PortalUser): string {
    return UserStatusMap.mapStatusToSelectItem(user.status).label;
  }

  // Get the string to display the tooltip (title) when the user hovers over the role
  public mapRoleToTooltip(user: PortalUser): string {
    if (user.status !== UserStatus.Active && user.status !== UserStatus.Approved) {
      return null;
    }

    const roleType = UserTypeMap.mapUserRoleTypeToSelectItem(user.roleID);
    return roleType ? roleType.label : '';
  }

  // Show the users in the collection.
  public findUsers(pageNumber: number, initialLoad: boolean = false): void {
    const collection: CollectionListItem = this.collectionService.selectedCollectionItem;
    if (collection != null && collection.id != null && collection.id != 0) {
      let sub = this.collectionService.getCollectionUsers(collection, 1, 99999).subscribe(response => {
        this.showUsers(pageNumber, response.totalItems, response.items);
        const selected = this.adminService.selectedUser;
        if (selected != null) {
          const selectedItem = response.items.find(x => x.email === selected.email);
          if (selectedItem != null) {
            this.selectedUser = selectedItem;
          }
        }
        if (initialLoad) {
          const user = this.adminService.selectedUser;
          if (user != null) {
            const mappedUser = this.allUsers.find(u => u.suspenseId === user.suspenseId);
            this.editUser = mappedUser;
            this.onEditUser(mappedUser);
          }
        }
        if (sub != null) {
          sub.unsubscribe();
        }
      });
    }
    else {
      this.showUsers(1, 0, null);
    }
  }

  public sortClass(column: string): string {
    let cls: string;
    if (column == this.sortColumn) {
      cls = this.sortAscending ? "fa fa-chevron-up" : "fa fa-chevron-down";
    }
    else {
      cls = "fa fa-chevron-up";
    }
    return cls;
  }

  public onSort(e: any): void {
    this.allUsers.sort((data1, data2) => {
      let value1 = data1[e.field];
      let value2 = data2[e.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (e.order * result);
    });
    this.sortAscending = e.order == 1;
    this.sortColumn = e.field;
    this.onPage(this.pageNumber);
  }

  public onPage(page: number): void {
    this.pageNumber = page;
    const start = (page - 1) * this.rowsPerPage;
    let end = start + this.rowsPerPage;
    if (end > this.allUsers.length) {
      end = this.allUsers.length;
    }
    // Select the subset of users to display in the grid
    this.users = this.allUsers.slice(start, end);
  }

  // Event sent by child dialogs to update the list of users of the collection.
  public onUserUpdated(event): void {
    // set to check Pending status because edituser.status hasn't been updated yet
    // TODO: make editable user reactive or use two-way binding to update local
    //       then change userstatus.approved since update should match. Will still
    //       need flag
    if (event.action === 'PendingApprove') {
      this.showApproveDeclineScreen = false;
      this.showUserPermission = true;
      return;
    }
    else if (event.action === 'PendingDecline') {
      this.showApproveDeclineScreen = false;
      this.showUserDeclineScreen = true;
      return;
    }

    if (this.editUser != null && this.editUser.status === UserStatus.Pending && event.action === 'Approve') {
      this.messageService.add({ severity: this.messages.SeveritySuccess, detail: this.messages.MessageUserApproved, life: this.messages.ToasterLife });
    }

    this.removalUser = null;
    this.showApproveDeclineScreen = false;

    if (event.action === 'Remove') {
      this.pageNumber = 1;
    }
    if (event && event.action !== 'Cancel') {
      this.findUsers(this.pageNumber);
    }
  }

  public onUserPermissionUpdated(event): void {
    this.showUserPermission = false;
    if (event && event.action !== 'Cancel') {
      this.findUsers(this.pageNumber);
    }
  }

  public onUserDeclineUpdated(event): void {
    this.showUserDeclineScreen = false;
    if (event && event.action === 'Decline') {
      this.findUsers(this.pageNumber);
    }
  }

  public onAddUser(): void {
    this.showAddUser = true;

  }

  // Set the user to be edited that will be input to the edit user dialog.
  public onEditUser(user: PortalUser) {
    this.editUser = user;
    if (user.status === UserStatus.Pending) {
      this.showApproveDeclineScreen = true; // enable accept/decline dialog
    } else {
      this.showUserPermission = true; // enable user permission slider only
    }
  }

  // Set the user to be deleted that will be input to the remove user dialog.
  public onRemoveUser(user: PortalUser) {
    this.removalUser = user;
  }

  public onShowAddStatus(status: boolean) {
    this.showAddUser = status;
  }

  public onAddUserToCollection(addUser: PortalUser) {
    // ensure add user dialog closed
    this.showAddUser = false;
    this.showUserPermission = true;
    // Show editbox
    this.editUser = addUser;
  }
}
