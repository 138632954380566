<div class="collection-users-empty flex-center" *ngIf="users == null || users.length == 0">
    No users in collection
</div>
<div class="collection-users">
    <div class="collection-users-controls" *ngIf="session.isEnterpriseAdmin">
        <div class="collection-users-add">
            <div class="button" (click)="onAddUser()">
                Add
                <div class="fa fa-plus-circle"></div>
            </div>
        </div>
    </div>
    <div class="collection-user-list">
        <p-table #collectionUserList [value]="users" [paginator]="false" [resizableColumns]="true"
             selectionMode="single" [(selection)]="selectedUser" (onSort)="onSort($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="status" width="10%" pResizableColumn>Status<div class="{{sortClass('status')}}"></div></th>
                    <th pSortableColumn="userType" width="10%" pResizableColumn>Role<div class="{{sortClass('userType')}}"></div></th>
                    <th pSortableColumn="name" width="25%" pResizableColumn>Name<div class="{{sortClass('name')}}"></div></th>
                    <th pSortableColumn="email" width="55%" pResizableColumn>Email<div class="{{sortClass('email')}}"></div></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
                <tr [pSelectableRow]="row">
                    <td>
                        <div class="data-icon text-center">
                            <i id="userStatus{{rowIndex}}" [ngClass]="mapStatusToIcon(row)" [title]="mapStatusToTooltip(row)"></i>
                        </div>
                    </td>
                    <td>
                        <div class="data-icon text-center">
                            <i id="userRow{{rowIndex}}" [ngClass]="mapRoleToIcon(row)" [title]="mapRoleToTooltip(row)"></i>
                        </div>
                    </td>
                    <td>
                        <div class="data-cell">
                            {{row.name}}
                        </div>
                    </td>
                    <td>
                        <div class="data-cell row">
                            <div class="col-md-9">
                                {{row.email}}
                            </div>
                            <div class="col-md-1" (click)="onEditUser(row)">
                                <i class="fa fa-pencil-square-o"></i>
                            </div>
                            <div class="col-md-1" (click)="onRemoveUser(row)">
                                <i class="fa fa-times"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <app-paginator [totalRecords]="totalUsers" resultLabel="users" [pageNumber]="pageNumber" [rowsPerPage]="rowsPerPage"(onPage)="onPage($event)"></app-paginator>
            </ng-template>
        </p-table>
    </div>
</div>
<app-user-approve-decline-dialog [user]="editUser" (userUpdated)="onUserUpdated($event)" [display]="showApproveDeclineScreen"></app-user-approve-decline-dialog>
<remove-user-dialog [user]="removalUser" (userUpdated)="onUserUpdated($event)" [display]="removalUser != null"></remove-user-dialog>
<add-user-dialog [display]="showAddUser" (showStatus)="onShowAddStatus($event)" (addUserStatus)="onAddUserToCollection($event)"
  [collectionItem]="collectionItem"></add-user-dialog>
<app-user-permission-dialog
  [user]="editUser"
  [display]="showUserPermission"
  (userUpdated)="onUserPermissionUpdated($event)">
</app-user-permission-dialog>
<app-user-decline-dialog
  [user]="editUser"
  (userUpdated)="onUserDeclineUpdated($event)"
  [display]="showUserDeclineScreen">
</app-user-decline-dialog>
